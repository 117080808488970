import {useLazyQuery} from "@apollo/client";
import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import {UPDATE_MANUAL_ADJUSTMENT} from "graphql/coprocessing/batches";
import {ERRORS} from "modules/co-processing/constants/batches";
import {SUCCESS_SAVE} from "modules/co-processing/constants/coProcessing";
import PropTypes from "prop-types";
import {formatNumber} from "providers/userSettings";
import {useMemo, useState} from "react";
import {toast} from "react-toastify";
import {Popover, PopoverBody} from "reactstrap";
import DHDSEditModal from "./DHDSEditModal";

const DHDSDataGridItem = ({
  batchId,
  dataBbl,
  dataLb,
  label,
  aimTag,
  metric = null,
  refetch,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);

  const sanitizeString = (id) =>
    id?.replace(/\s+/g, "-")?.replace(/[^a-zA-Z0-9-_]/g, "");

  const sanitizedTagId = useMemo(
    () => `aim-data-point-${sanitizeString(label)}-${sanitizeString(aimTag)}`,
    [label, aimTag],
  );

  const getDisplayedValue = (data, metric) => {
    if (!data) {
      return "";
    }
    return `${formatNumber(data, "Imperial Style")} ${metric}`;
  };

  const [editManualAdjustment] = useLazyQuery(UPDATE_MANUAL_ADJUSTMENT, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      const response = result?.bioLcCoproUsBatchesApi;
      if (response?.statusCode === 200) {
        toast.success(SUCCESS_SAVE);
        refetch();
      } else {
        toast.error(response?.error || ERRORS.FAILED_ADJUSTMENT);
      }
    },
  });

  const updateManualAdjustment = (value) => {
    const variables = {
      batch_resource_id: batchId,
      updates: [
        {
          column: "manual_adjustment_bbl",
          value,
        },
      ],
    };
    editManualAdjustment({variables});
  };

  return (
    <div
      data-test="data-grid-item"
      className="grid grid-cols-3 place-content-between py-3 text-xs border-b border-gray-100"
    >
      <div
        id={sanitizedTagId}
        className="text-secondary"
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        {label}
      </div>
      <div className="data-grid-item-value text-right">
        {getDisplayedValue(dataBbl, metric ?? "bbl")}
        {label === "Manual Adjustment" && (
          <Edit24
            data-test="manual-adjustment-edit-btn"
            className="grid edit-icon ml-2 cursor-pointer justify-self-end"
            onClick={toggleModal}
          />
        )}
      </div>
      <div className="data-grid-item-value text-right">
        {getDisplayedValue(dataLb, "lb")}
      </div>

      {aimTag && (
        <Popover
          popperClassName="copied-popover"
          innerClassName="copied-popover-inner"
          data-test="aims-tag-popover"
          placement="top"
          isOpen={tooltipOpen}
          target={sanitizedTagId}
        >
          <PopoverBody>{`AIM #: ${aimTag}`}</PopoverBody>
        </Popover>
      )}
      <DHDSEditModal
        data={dataBbl}
        setModal={setModalOpen}
        modal={isModalOpen}
        saveValue={updateManualAdjustment}
      />
    </div>
  );
};

DHDSDataGridItem.propTypes = {
  batchId: PropTypes.string,
  dataBbl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataLb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  aimTag: PropTypes.string,
  metric: PropTypes.string,
  refetch: PropTypes.func,
};

export default DHDSDataGridItem;
