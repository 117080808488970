import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({className, ...props}) => (
  <input
    className={`w-5 h-5 border bg-white  ${className ?? ""}`}
    type="checkbox"
    {...props}
  />
);

Checkbox.propTypes = {
  className: PropTypes.string,
};
export default Checkbox;
