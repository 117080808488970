import {Close} from "@bphxd/ds-core-react";
import {BUTTONS} from "modules/co-processing/constants/batches";
import PropTypes from "prop-types";
import {useCallback, useState} from "react";
import {Button, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";

const DHDSEditModal = ({data, modal, setModal, saveValue}) => {
  const [adjustmentValue, setAdjustmentValue] = useState(data);

  const handleChange = useCallback((event) => {
    const {value} = event.target;
    setAdjustmentValue(value);
  }, []);

  const close = () => {
    setModal(false);
  };

  const onSave = () => {
    saveValue(adjustmentValue);
    close();
  };

  return (
    <div>
      <Modal size="sm" unmountOnClose centered isOpen={modal}>
        <ModalHeader
          className="border border-b"
          close={<Close className="custom-close" onClick={() => close()} />}
        >
          Manual Adjustment
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="px-4 pt-6">
            <Label className="text-gray-700 text-sm mb-2 font-bold">
              Enter adjusted amount (BBLs)
            </Label>
            <Input
              data-test="copro-metric-adjustment-input"
              className="min-w-[169px] text-xs"
              value={adjustmentValue}
              onChange={(event) => handleChange(event)}
            />
            <Label className="text-gray-700 text-sm mb-2">
              Enter a positive value to increase Net Renewable Feed.
            </Label>
            <Label className="text-gray-700 text-sm mb-2">
              Enter a negative value to decrease Net Renewable Feed.
            </Label>
          </div>
          <div className="flex flex-row w-full border border-t mt-32">
            <Button
              className="w-1/2 !text-gray-400"
              color=""
              onClick={() => close()}
            >
              {BUTTONS.CANCEL}
            </Button>
            <span>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: "#ededed",
                }}
              />
            </span>
            <Button
              className="w-1/2 disabled:border-0"
              color=""
              onClick={() => onSave()}
            >
              {BUTTONS.SAVE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

DHDSEditModal.propTypes = {
  data: PropTypes.number,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  saveValue: PropTypes.func,
};

export default DHDSEditModal;
