import {Close} from "@bphxd/ds-core-react";
import {actualisationTypes} from "constants/actualization";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {getFormattedNumberWithCheck} from "utils/numberUtil";

const ActualisationToleranceModal = ({
  showModal,
  setShowModal,
  linkageType,
  incomingTotal,
  purchaseTotal,
  receivedTotal,
  onSubmit,
  loading,
}) => {
  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {division = "copro"} = useParams();

  const {
    register,
    formState: {errors},
    getValues,
    trigger,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  let headerText = "";
  let errorText = "";
  let reasonFieldText = "";

  switch (linkageType) {
    case actualisationTypes.WRITE_OFF:
      headerText = "Link and write off excess quantity";
      errorText = "Write off reason is required";
      reasonFieldText = "Reason for writing off excess quantity";
      break;
    case actualisationTypes.REMINDER:
      headerText = "Link and keep remaining quantity";
      errorText = "Keep remaining reason is required";
      reasonFieldText = "Reason for keeping remaining quantity";
      break;
    default:
      headerText = "Link with full quantity";
      errorText = "Link with full quantity reason is required";
      reasonFieldText = "Reason for linking with full received quantity";
  }

  const getUnitOfMeasure = useMemo(() => {
    switch (division?.toUpperCase()) {
      case DIV_CODE_COPRO:
        return "MT";
      default:
        return "m³";
    }
  }, [division]);

  const getPurchaseQtyText = useMemo(() => {
    switch (division?.toUpperCase()) {
      case DIV_CODE_COPRO:
        return "Received quantity";
      default:
        return "Actualizable quantity";
    }
  }, [division]);

  const getActualizableQty = useMemo(() => {
    switch (division?.toUpperCase()) {
      case DIV_CODE_COPRO:
        return receivedTotal;
      default:
        return purchaseTotal;
    }
  }, [division, receivedTotal, purchaseTotal]);

  return (
    <Modal
      size="sm"
      isOpen={showModal}
      className="modal-dialog-centered [&>div]:w-[360px]"
    >
      <ModalHeader
        className="w-[360px] !border-b-[1px] !border-gray-200 mb-2 text-xl !px-[20px]"
        close={<Close onClick={() => setShowModal(false)} />}
      >
        {headerText}
      </ModalHeader>
      <ModalBody className="flex flex-col items-center !px-[20px]">
        <div className="w-full flex justify-between">
          <p className="text-[14px] text-[#11111191]">Document quantity</p>
          <p className="text-[14px]">
            {getFormattedNumberWithCheck(incomingTotal, NUMBER_OF_DECIMALS)}{" "}
            {getUnitOfMeasure}
          </p>
        </div>
        <div className="w-full flex justify-between mb-[9px]">
          <p className="text-[14px] text-[#11111191]">{getPurchaseQtyText}</p>
          <p className="text-[14px]">
            {getFormattedNumberWithCheck(
              getActualizableQty,
              NUMBER_OF_DECIMALS,
            )}{" "}
            {getUnitOfMeasure}
          </p>
        </div>
        <div className="w-full">
          <FormProvider {...methods}>
            <Form>
              <Label for="reason">{reasonFieldText}</Label>
              <Input
                id="reason"
                type="textarea"
                rows="3"
                {...computeProps("reason", {
                  required: errorText,
                })}
                invalid={!!errors.reason}
              />
              {errors.reason && (
                <FormFeedback>{errors.reason.message}</FormFeedback>
              )}
              <FormFeedback className="!block !text-gray-700 !tracking-[0.2px]">
                Provide reason and evidence to support linking outside of 0.5%
                tolerance.
              </FormFeedback>
            </Form>
          </FormProvider>
        </div>
      </ModalBody>

      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={() => {
                trigger().then((isValid) => {
                  if (isValid) {
                    onSubmit(getValues());
                  }
                });
              }}
              disabled={errors.reason || loading}
            >
              {loading && <Spinner size="sm" className="btn-icon-prefix" />}
              Confirm linking
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ActualisationToleranceModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  linkageType: PropTypes.string,
  incomingTotal: PropTypes.number,
  purchaseTotal: PropTypes.number,
  receivedTotal: PropTypes.number,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default ActualisationToleranceModal;
