import {Down32, Up32} from "@bphxd/ds-core-react/lib/icons";
import {PARCEL_STATUS} from "modules/co-processing/constants/receipts";
import Checkbox from "modules/common/Checkbox";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Collapse} from "reactstrap";
import StatusBadge from "../Shared/StatusBadge";

const CollapsibleCard = ({
  title,
  defaultChecked = false,
  onCheck,
  isSchedulable,
  indexOfRow,
  children,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onCheck((prev) => ({
      ...prev,
      [indexOfRow]: isChecked,
    }));
  }, [isChecked, onCheck, indexOfRow]);

  return (
    <Card
      id="collapsible-card"
      className="mb-4 bg-white"
      data-test="copro-collapsible-card"
    >
      <CardHeader
        className="bg-white border !border-b-0 flex justify-between items-center m-0 py-4 px-5"
        data-test="copro-collapsible-card-header"
      >
        <span
          data-test="copro-collapsible-card-title"
          id="copro-collapsible-card-title"
          className="fw-normal flex items-center gap-4"
        >
          <Checkbox
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
            }}
            disabled={!isSchedulable}
            data-test="copro-collapsible-card-checkbox"
          />
          {title}
        </span>
        <div className="flex justify-end gap-5 items-center">
          {!isSchedulable && <StatusBadge value={PARCEL_STATUS.NOT_READY} />}

          <button
            type="button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            data-test="toggle-collapse-btn"
          >
            {isOpen ? <Up32 /> : <Down32 />}
          </button>
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="border" data-test="copro-collapsible-content">
          {children}
        </CardBody>
      </Collapse>
    </Card>
  );
};

CollapsibleCard.propTypes = {
  title: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  isSchedulable: PropTypes.bool,
  indexOfRow: PropTypes.number,
  children: PropTypes.any,
};

export default CollapsibleCard;
