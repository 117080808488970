import DHDSDataGrid from "modules/co-processing/components/DetailsScreen/DHDSDataGrid";
import {
  BATCH_LABELS,
  DHDS_FEED_COLUMN,
  DHDS_PRODUCTION_COLUMN,
  DHDS_SUMMARY_LABELS,
  OP_CONDITIONS,
} from "modules/co-processing/constants/batches";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";

const DHDSSummary = ({data, refetch}) => {
  const isByPass = data.operating_condition === OP_CONDITIONS.BY_PASSED;

  return (
    <div>
      <Card className="card-border rounded-6">
        <CardBody>
          <div className="text-[26px] pb-6">{BATCH_LABELS.DHDS_SUMMARY}</div>
          <div className="grid grid-cols-1 gap-16 pb-12">
            <DHDSDataGrid
              data={isByPass ? {} : data?.details}
              title={DHDS_SUMMARY_LABELS.DHDS_PRODUCTION}
              dataColumn={DHDS_PRODUCTION_COLUMN}
              refetch={refetch}
            />
            <DHDSDataGrid
              data={isByPass ? {} : data?.details}
              title={DHDS_SUMMARY_LABELS.DHDS_FEED}
              dataColumn={DHDS_FEED_COLUMN}
              refetch={refetch}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

DHDSSummary.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
};

export default DHDSSummary;
