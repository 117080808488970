import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const REVERSAL_MUTATION = gql`
  mutation bioLcGlobalMassBalanceReversal(
    $event: bioLcGlobalMassBalanceReversalRequest!
  ) {
    bioLcGlobalMassBalanceReversal(event: $event) {
      error
      statusCode
      errorCode
      errorDetails {
        mbBalanceTypeCode
        mbBalanceTypeId
        mbLocationGroupName
        mbLocationGroupId
        mbPeriodName
        mbPeriodId
        mbBalanceGroupName
        mbBalanceGroupId
        divisionId
        siteReferenceId
      }
    }
  }
`;

export const ReversalMutationAPI = (data) =>
  client.mutate({
    mutation: REVERSAL_MUTATION,
    variables: {
      ...data,
    },
  });

export default {
  REVERSAL_MUTATION,
  ReversalMutationAPI,
};
