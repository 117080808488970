export default function appendParamsToUrl(
  url,
  pathParams = [],
  queryParams = {},
) {
  let newUrl = url;

  // Append path parameters
  pathParams.forEach((param) => {
    const separator = "/";
    newUrl += `${separator}${encodeURIComponent(param)}`;
  });

  // Append query parameters
  const queryKeys = Object.keys(queryParams);
  if (queryKeys.length > 0) {
    const queryString = queryKeys
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`,
      )
      .join("&");
    newUrl += `?${queryString}`;
  }

  return newUrl;
}

export const isValidString = (str) => {
  return str === "null" || str === "undefined" ? undefined : str;
};
