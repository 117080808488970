import {Avatar} from "@bphxd/ds-core-react";
import {LogOut24, Settings24} from "@bphxd/ds-core-react/lib/icons";
import {countries} from "content/countryList";
import {uniqBy} from "lodash";
import FormDropdownWithIcon from "modules/common/FormDropdown/FormDropdownWithIcon";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting";
import {useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {DropdownItem, DropdownMenu, UncontrolledDropdown} from "reactstrap";

export const Profile = ({
  isAuthenticated,
  userDetails,
  userInitials,
  photoSrc,
  handleLogout,
  setShowSettings,
}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const navigate = useNavigate();
  const location = useLocation();
  const uniqueCountries = uniqBy(
    appSetting?.featureMappingData ?? [],
    "countryName",
  );

  useEffect(() => {
    const countryFromUrl = location.pathname.split("/")[2];
    if (countryFromUrl && countryFromUrl !== appSetting?.currentCountry) {
      const countryFeatureData = appSetting?.featureMappingData?.find(
        (item) =>
          item.countryName.toLowerCase() === countryFromUrl.toLowerCase(),
      );

      if (countryFeatureData) {
        setAppSetting((appSetting) => ({
          ...appSetting,
          currentCountry: countryFeatureData.countryName,
          currentCountryMappingData: countryFeatureData,
          currentCountryCode: countryFeatureData.countryCode,
        }));
      }
    }
  }, [
    location.pathname,
    appSetting?.currentCountry,
    appSetting?.featureMappingData,
    setAppSetting,
  ]);

  const handleCountryChange = (country) => {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName === country,
    );
    setAppSetting({
      ...appSetting,
      currentCountry: country,
      featureMappingData: appSetting?.featureMappingData,
      currentCountryMappingData: countryFeatureData,
      currentModule: "",
      currentModuleTitle: "",
      currentCountryCode: countryFeatureData?.countryCode,
    });
    const fromUrl = location.pathname.split("/");
    const [, , , initialDivision, listDivision] = fromUrl;
    const division = fromUrl.includes("list") ? listDivision : initialDivision;

    if (country === "GLOBAL") {
      navigate("/");
    }
    navigate(
      `/dashboard/${country.toLowerCase()}/${division.toLocaleLowerCase()}`,
      {replace: true},
    );
  };

  return (
    <div className="bp-avatar-item">
      {isAuthenticated ? (
        <div className="flex flex-row gap-4 items-center">
          <div className="w-[199px] country-dropdown">
            <FormDropdownWithIcon
              options={uniqueCountries?.map((country) =>
                country.countryName?.toLowerCase(),
              )}
              values={uniqueCountries?.map((country) => country.countryName)}
              icons={uniqueCountries?.map(
                (country) =>
                  countries?.find(
                    (x) =>
                      x.countryName?.toLowerCase() ===
                      country.countryName?.toLowerCase(),
                  )?.img,
              )}
              data-test="periodYear"
              placeholder="Global"
              menuClassName="country-dropdown-filter w-[199px]"
              value={appSetting?.currentCountry}
              onChange={(value) => handleCountryChange(value)}
            />
          </div>

          <UncontrolledDropdown>
            <Avatar initials={userInitials} dropdown image={photoSrc} />
            <DropdownMenu>
              <DropdownItem data-test="user-profile-name">
                <Avatar
                  online
                  initials={userInitials}
                  href="#"
                  image={photoSrc}
                  className="me-4"
                />
                <div>
                  <div className="opacity-80">{userDetails?.name}</div>
                  <div className="opacity-60 small-md">
                    {userDetails?.username}
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setShowSettings(true);
                }}
                data-test="user-profile-settings"
              >
                <Settings24 className="x5-ms-n1 me-3" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem
                onClick={(event) => handleLogout(event)}
                data-test="user-profile-logout"
              >
                <LogOut24 className="x5-ms-n1 me-3" />
                <span>Log Out</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ) : (
        <Link to="/login" data-test="login-button" className="bp-login-button">
          Log in
        </Link>
      )}
    </div>
  );
};

Profile.propTypes = {
  isAuthenticated: PropTypes.bool,
  userDetails: PropTypes.any,
  userInitials: PropTypes.any,
  photoSrc: PropTypes.string,
  setShowSettings: PropTypes.func,
  handleLogout: PropTypes.func,
};

export default Profile;
