import {Close, ReactTable} from "@bphxd/ds-core-react";
import {Alert32} from "@bphxd/ds-core-react/lib/icons";
import {
  contractSchedulingHeaders,
  SCHEDULE_MODAL,
} from "modules/co-processing/constants/receipts";
import buildColumns from "modules/co-processing/helpers/buildColumns";
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CollapsibleCard from "./CollapsibleCard";

const ContractsModal = ({
  receiptsData = [],
  isModalOpen,
  onCloseModal,
  onConfirm,
  onCheckFunc,
}) => {
  const dataColumns = buildColumns(contractSchedulingHeaders);

  return (
    <Modal
      contentClassName="rounded-0"
      isOpen={isModalOpen}
      size="lg"
      data-test="contracts-modal"
    >
      <ModalHeader
        className="border border-b rounded-0"
        close={
          <Close onClick={onCloseModal} data-test="contracts-modal-close-btn" />
        }
      >
        {SCHEDULE_MODAL.HEADER}
      </ModalHeader>

      <ModalBody className="bg-light" data-test="contracts-modal-body">
        {receiptsData.map((receipt, index) => {
          const indexString = `${receipt.trade_reference}-${receipt.leg_number}-${receipt.feedstock_type}`;
          return (
            <CollapsibleCard
              key={indexString}
              title={`Contract ${receipt.trade_reference}: ${receipt.leg_number} (${receipt.feedstock_type})`}
              onCheck={onCheckFunc}
              isSchedulable={receipt.schedulable_records}
              indexOfRow={index}
            >
              {receipt.schedulable_records ? (
                <ReactTable
                  responsive
                  columns={dataColumns}
                  data={receipt.bols_details}
                  enableSorting={false}
                  showFirstAndLast={receipt.bols_used.length / 10 > 5}
                />
              ) : (
                <div
                  className="flex font-light justify-left items-center mt-3 mb-5 border-2 rounded copro-red-chip-border copro-red-chip py-3 px-3"
                  data-test="bol-modal-two-weeks-warning"
                >
                  <Alert32 className="mb-1" color="black" />
                  <span className="bol-modal-top-value">
                    {SCHEDULE_MODAL.INCOMPLETE_BOLS_ERROR}
                  </span>
                </div>
              )}
            </CollapsibleCard>
          );
        })}
      </ModalBody>

      <ModalFooter className="bg-light">
        <Button
          className="btn btn-light rounded-0 mr-2"
          onClick={onCloseModal}
          data-test="contracts-modal-cancel-btn"
        >
          {SCHEDULE_MODAL.CANCEL_BUTTON}
        </Button>
        <Button
          className="rounded-0"
          form="add_batch_form"
          color="primary"
          onClick={onConfirm}
          // Disable button when mutation is in progress
          data-test="confirm-contract-schedule-btn"
        >
          {/* <Spinner size="sm" className="btn-icon-prefix" /> */}
          {SCHEDULE_MODAL.CONFIRM_BUTTOM}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ContractsModal.propTypes = {
  receiptsData: PropTypes.array,
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onConfirm: PropTypes.func,
  onCheckFunc: PropTypes.func,
};

export default ContractsModal;
