/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const COPRO_US_API_DATA = gql`
  query GetCoproUsApiDataQuery(
    $tracking_type: String
    $date_range: CoproUsApiDateRangeItem
    $search_text: String
  ) {
    bioLcCoproUsApi(
      event: {
        tracking_type: $tracking_type
        date_range: $date_range
        search_text: $search_text
      }
    ) {
      statusCode
      error
      count
      data {
        delivered_from
        trade_reference
        leg_number
        parcel_number
        shipment_id
        bol_number
        status
        shipment_date
        tank_number
        feedstock_type
        feedstock_percentage
        feedstock_qty
        feedstock_qty_uom
        density
        density_uom
        certificate_number
      }
      sheets_data {
        sheet_2 {
          vendor_sales_contract
          trade_reference
          owb_batch_tank
          density
          feedstock_qty
          portion_canola
          portion_carinata
          portion_cwg
          portion_dco
          portion_other
          portion_pome
          portion_soy
          portion_tallow
          portion_uco

          product_canola
          product_carinata
          product_cwg
          product_dco
          product_other
          product_pome
          product_soy
          product_tallow
          product_uco
        }
        sheet_3 {
          vendor_sales_contract
          owb_batch_tank
          shipment_date
          feedstock_qty
        }
        sheet_4 {
          vendor_sales_contract
          owb_batch_tank
          shipment_date
          feedstock_qty
          batch_id
        }
      }
    }
  }
`;

export const COPRO_US_API_CONTRACT_DATA = gql`
  query GetCoproUsApiDataQuery(
    $tracking_type: String
    $date_range: CoproUsApiDateRangeItem
  ) {
    bioLcCoproUsApi(
      event: {tracking_type: $tracking_type, date_range: $date_range}
    ) {
      statusCode
      error
      count
      data {
        delivered_from
        trade_reference
        leg_number
        parcel_number
        shipment_id
        status
        date_range
        feedstock_type
        feedstock_qty
        feedstock_qty_mt
        feedstock_qty_uom
        bols_used
        open_balance
        schedulable_records
        bols_details {
          shipment_date
          certificate_number
          bol_number
          feedstock_percentage
          feedstock_qty
        }
      }
    }
  }
`;

export const COPRO_US_API_SAVE_DRAFT_DATA = gql`
  query GetCoproUsApiDataQuery(
    $tracking_type: String
    $date_range: CoproUsApiDateRangeItem
    $data_to_insert: [CoproUsApiDraftDataItem]
  ) {
    bioLcCoproUsApi(
      event: {
        tracking_type: $tracking_type
        date_range: $date_range
        data_to_insert: $data_to_insert
      }
    ) {
      statusCode
      error
      count
    }
  }
`;

export const COPRO_US_TASKS_API = gql`
  query GetCoproUsTasksApiQuery(
    $op: String
    $ids: [String]
    $task_type: String
  ) {
    bioLcTasksApi(event: {op: $op, ids: $ids, task_type: $task_type}) {
      statusCode
      body {
        data {
          inbound_doc_status_us_id
          country_code
          doc_type
          doc_name
          textract_job_id
          textract_status
          file_processed_status
          confidence_score_percentage
          notification_ack
          bv_audit_created_datez
          bv_audit_created_by
          bv_audit_changed_datez
          bv_audit_changed_by
          resource_id {
            cofa_id
            bol_ids
            c14_id
          }
          view_url
          download_url
        }
      }
    }
  }
`;

export const COPRO_US_COFA_API = gql`
  query GetCoproUsCofaApiQuery(
    $op: String
    $cofa_id: String
    $updates: [CofaUsDetailsUpdate]
  ) {
    bioLcCofaUsDetailsApi(
      event: {op: $op, cofa_id: $cofa_id, updates: $updates}
    ) {
      statusCode
      body {
        data {
          cofa_id
          certificate_number
          customer_name
          customer_product_desc
          sample_source
          sample_location
          sampled_by
          sampled_datetime
          tested_datetime
          tallow_concentration
          corn_oil_concentration
          soy_concentration
          canola_oil_concentration
          used_cooking_oil_concentration
          choice_white_grease_concentration
          carinata_oil_concentration
          bv_audit_created_by
          bv_audit_changed_by
          sampled_datez
          tested_datez
          canola_oil_concentration
          bv_audit_created_datez
          bv_audit_changed_datez
          density
        }
      }
    }
  }
`;

export default COPRO_US_API_DATA;
