import {useLazyQuery, useQuery} from "@apollo/client";
import {ReactTable} from "@bphxd/ds-core-react";
import {
  COPRO_US_API_CONTRACT_DATA,
  COPRO_US_API_SAVE_DRAFT_DATA,
} from "graphql/coprocessing/receipts";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import {useEffect, useMemo, useState} from "react";
import {Button, Nav, NavItem, NavLink} from "reactstrap";
import tw from "twin.macro";
import {
  CONTRACT_STATUS,
  TABLE_TITLE,
  contractDraftHeaders,
  contractSummaryHeaders,
} from "../../constants/receipts";
import {useStatus} from "../../context/StatusContext";
import buildColumns, {filterDraftColumns} from "../../helpers/buildColumns";
import ContractsModal from "./ContractsModal";

const GraphContainer = tw.div`mt-12 xl:w-[445px]`;
const GraphBody = tw.div`flex bg-white py-4 w-full justify-center`;
const ContainerTitle = tw.div`h-[52px] flex bg-white items-center pl-[15px] border-b`;
const ContainerHeader = tw.div`h-[52px] flex items-center pl-[15px] border-b justify-between`;

const ContractSummary = () => {
  const {dateRange, isScheduled, setIsScheduled} = useDateRange();
  const {
    state: {isDraft},
    dispatch,
  } = useStatus();
  const [selectedRows, setSelectedRows] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(CONTRACT_STATUS.DRAFT);

  const handleSchedule = () => {
    setShowModal(false);
    setIsScheduled(true);
  };

  const initialVariables = {
    tracking_type: isDraft
      ? CONTRACT_STATUS.STATUS.toLowerCase()
      : CONTRACT_STATUS.SUMMARY.toLowerCase(),
    date_range: {
      start_date: dateRange.from,
      end_date: dateRange.to,
    },
  };

  const getHeadersForColumn = () => {
    if (isDraft) {
      return contractDraftHeaders;
    }
    return contractSummaryHeaders;
  };

  const {data} = useQuery(COPRO_US_API_CONTRACT_DATA, {
    variables: initialVariables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [scheduleQuery] = useLazyQuery(COPRO_US_API_SAVE_DRAFT_DATA, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setIsScheduled(false);
      setSelectedRows({});
      dispatch({type: "setDraft", payload: false});
    },
  });

  useEffect(() => {
    if (isScheduled) {
      const filteredDraftColumns = () => {
        if (Object.keys(selectedRows).length === 0 || !isDraft) return {};
        return filterDraftColumns(data?.bioLcCoproUsApi?.data, selectedRows);
      };
      const draftVariables = {
        tracking_type: "insert_drafts",
        data_to_insert: filteredDraftColumns(),
        date_range: {
          start_date: dateRange.from,
          end_date: dateRange.to,
        },
      };

      scheduleQuery({variables: draftVariables});
    }
  }, [isScheduled, scheduleQuery, data, dateRange, isDraft, selectedRows]);

  const contractData = useMemo(() => {
    const dataArray = data?.bioLcCoproUsApi?.data;
    if (!Array.isArray(dataArray)) return [];

    const adjustedData = dataArray.map((item) => {
      return {
        ...item,
        feedstock_qty_col: item.feedstock_qty
          ? `${item.feedstock_qty.toLocaleString()} ${item.feedstock_qty_uom}`
          : 0,
        status:
          !item.schedulable_records && item.status === CONTRACT_STATUS.DRAFT
            ? "Not Ready"
            : item.status,
      };
    });

    return adjustedData;
  }, [data]);

  const dataColumns = buildColumns(getHeadersForColumn());

  return (
    <div>
      <div
        data-test="copro-status-pills"
        className="copro-status-pills  w-full"
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              data-test="copro-draft-pill"
              active={activeTab === CONTRACT_STATUS.DRAFT}
              onClick={() => {
                setActiveTab(CONTRACT_STATUS.DRAFT);
                dispatch({type: "setDraft", payload: true});
              }}
            >
              {CONTRACT_STATUS.DRAFT}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-test="copro-summary-pill"
              active={activeTab === CONTRACT_STATUS.SUMMARY}
              onClick={() => {
                setActiveTab(CONTRACT_STATUS.SUMMARY);
                dispatch({type: "setDraft", payload: false});
              }}
            >
              {CONTRACT_STATUS.SUMMARY}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div
        data-test="copro-contract-summary"
        className="copro-contract-summary flex flex-wrap justify-between pb-[100px] w-full pt-4"
      >
        <div
          data-test="copro-contract-summary-table"
          className="copro-table w-100"
        >
          <ContainerHeader
            data-test="copro-table-container-title"
            className="copro-container-title pb-4"
          >
            {TABLE_TITLE.CONTRACTS}{" "}
            <ContractsModal
              isModalOpen={showModal}
              onCloseModal={() => {
                setShowModal(false);
              }}
              receiptsData={contractData}
              onConfirm={handleSchedule}
              onCheckFunc={setSelectedRows}
            />
          </ContainerHeader>

          <ReactTable
            className="exmplae"
            responsive
            columns={dataColumns}
            data={contractData}
            enableSorting
            showPaginationBottom={false}
          />

          <div className="flex h-[52px] items-center justify-end">
            <Button
              className="rounded-0 enabled:border-black disabled:border-gray-300 text-white bg-black"
              color="standard-secondary"
              id="popover-schedule"
              onClick={() => {
                setShowModal(true);
              }}
              disabled={!dateRange || !isDraft}
            >
              {BUTTONS.SCHEDULE}
            </Button>
          </div>
        </div>

        {/* <GraphContainer
          className="contract-summary-graph"
          data-test="copro-contract-summary-graph"
        >
          <ContainerTitle
            data-test="copro-chart-container-title"
            className="copro-container-title"
          >
            {CONTRACT_SUMMARY_GRAPH}
          </ContainerTitle>
          <GraphBody>
            <SummaryBarChart summaryData={contractData}></SummaryBarChart>
          </GraphBody>
        </GraphContainer> */}
      </div>
    </div>
  );
};

export default ContractSummary;
