export const COUNTRY_SPAIN = "spain";
export const COUNTRY_UK = "united kingdom";
export const COUNTRY_POLAND = "poland";
export const COUNTRY_SWEDEN = "sweden";
export const COUNTRY_NORWAY = "norway";
export const COUNTRY_US = "united states";
export const COUNTRY_GERMANY = "germany";
export const COUNTRY_FRANCE = "france";
export const COUNTRY_AUSTRIA = "austria";
export const COUNTRY_PORTUGAL = "portugal";
export const COUNTRY_FINLAND = "finland";

export default {COUNTRY_SPAIN};
