import {Alert24, FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {status} from "modules/GlobalMassBalance/constants";
import {Link} from "react-router-dom";
import {
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";

import {
  getFilterValue,
  getStatusColor,
  getStatusValue,
} from "modules/GlobalMassBalance/utils";

import {NUMBER_OF_DECIMALS} from "constants/common";
import moment from "moment";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import ActionDropdown from "../../ActionDropdown";
import ColumnHighlight from "../../ColumnHighlight";

const getCurrentStatus = (row) => {
  let currentStatus = row?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    row?.original.inboundRecord?.fromMbLocationGroupId;

  const linkError = row?.original.outboundRecords?.linkError;

  const isDocumentGenerated =
    row?.original?.outboundRecords?.document !== null &&
    row?.original?.outboundRecords?.document !== "IN PROGRESS";

  if (
    linkError &&
    (linkError === "PURCHASE_SALES" || linkError === "PURCHASE")
  ) {
    currentStatus = status.INVALID_PURCHASE;
  } else if (
    linkError &&
    (linkError === "PURCHASE_SALES" || linkError === "SALES")
  ) {
    currentStatus = status.INVALID_OUTGOING;
  } else if (
    currentStatus === status.AVAILABLE &&
    fromMbLocationGroupId !== null
  ) {
    currentStatus = status.AVAILABLE_FROM_TRANSFER;
  } else if (currentStatus === status.AVAILABLE && isDocumentGenerated) {
    currentStatus = status.CARRY_OVER;
  }
  return currentStatus;
};

const checkForGenerateFolder = (row) => {
  const fromMbLocationGroupId =
    row?.original.inboundRecord?.fromMbLocationGroupId;
  return row?.original?.outboundRecords?.some((record) => {
    return (
      (record?.status === status.AVAILABLE && fromMbLocationGroupId !== null) ||
      (record?.status === status.AVAILABLE_CO &&
        record?.isReceipientPPOSExist !== null)
    );
  });
};

const getColumnsSafTrTrs = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  navigateToAllocation,
  currentFilterValues,
  siteReferenceId,
  divisionId,
  division,
  periodStatus,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecord?.physicalReceiptDate;
        },
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        size: 500,
        accessorFn: (row) => {
          return row?.inboundRecord?.incomingDocument || "";
        },
        cell: ({cell, row}) => {
          const tooltipId = `incomingDocument${row?.id}`;
          const isGenerated = checkForGenerateFolder(row);
          const uploadedDocUrl = appendParamsToUrl(
            "/document-manager/incoming",
            [
              selectedCountry,
              "detailedView",
              row?.original?.inboundRecord?.certificateInboundEuId,
            ],
            {
              siteReferenceId,
              divisionId,
            },
          );
          const generatedUrl = appendParamsToUrl(
            "/document-manager/generated-view",
            [
              selectedCountry,
              division,
              row?.original?.inboundRecord?.incomingDocument,
            ],
            {
              redirectTo: appendParamsToUrl("/mass-balance", [
                selectedCountry,
                division,
                currentFilterValues?.mbBalanceTypeCode,
                currentFilterValues?.mbLocationGroupName,
                currentFilterValues?.mbPeriodName,
                currentFilterValues?.mbBalanceGroupName,
              ]),
            },
          );
          const pathName = isGenerated ? generatedUrl : uploadedDocUrl;
          return (
            <>
              <Link to={pathName} className="link-dark" id={tooltipId}>
                <u className="flex">
                  {cell.getValue() && <FilePdf24 />}
                  <ColumnHighlight
                    value={cell.getValue()}
                    columnFilter={getFilterValue(cell, "incomingDocument")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {cell.getValue() ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.rawMaterial;
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.countryOfOrigin;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMj",
        key: "ghgTotalMj",
        visible: true,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.ghgTotal;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Actualized quantity (in) m³",
        accessorKey: "actualizedQuantityM3",
        key: "actualizedQuantityM3",
        visible: true,
        disabled: true,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.actualizedQuantityM3;
        },
        cell: ({cell, row}) => {
          const isPending =
            row?.original.outboundRecords.length === 1 &&
            row?.original.outboundRecords?.[0]?.status === status.PENDING;
          return (
            <span
              style={{
                color: isPending ? "rgba(17, 17, 17, 0.31)" : "auto",
              }}
            >
              {getFormattedNumberWithCheck(cell.getValue(), NUMBER_OF_DECIMALS)}
            </span>
          );
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDate",
        key: "issuanceDate",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecord?.issuanceDate;
        },
        cell: ({cell}) => {
          return (
            cell.getValue() &&
            moment(cell.getValue()).utcOffset(0).format(dateFormat)
          );
        },
      },
      {
        header: "Quantity(in) m³",
        accessorKey: "productQtyM3",
        key: "productQtyM3",
        visible: false,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyM3;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyMt;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.supplier;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportSystem;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.vesselName;
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoiceNumber",
        key: "invoiceNumber",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.invoiceNumber || "";
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLg",
        key: "transportLg",
        visible: false,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportLg;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Volume variation %",
        accessorKey: "volumeVariation",
        key: "volumeVariation",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.volumeVariation;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.certified || "";
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMt",
        key: "receivedQuantityMt",
        visible: false,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.receivedQuantityMt;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Actualized quantity (in) MT",
        accessorKey: "actualizedQuantityMt",
        key: "actualizedQuantityMt",
        visible: false,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.actualizedQuantityMt;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Receiving location",
        accessorKey: "receivingLocation",
        key: "receivingLocation",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.receivingLocation;
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavings",
        key: "ghgSavings",
        visible: false,
        dataType: "number",
        filterFn: "customGlobalNumberFilter",
        accessorFn: (row) => {
          return row?.inboundRecord?.ghgSavings;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
  {
    header: "Outgoing",
    columns: [
      {
        header: "Quantity(out) m³",
        accessorKey: "quantityOutM3",
        key: "quantityOutM3",
        visible: true,
        disabled: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.quantityOutM3,
            NUMBER_OF_DECIMALS,
            true,
          );
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecords?.quantityOutM3;
          return getFormattedNumberWithCheck(value, NUMBER_OF_DECIMALS, true);
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.outboundType;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.outboundType;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.recipient;
        },
        cell: ({row}) => {
          const isReceipientPPOSExist =
            row?.original.outboundRecords?.isReceipientPPOSExist;
          const tooltipId = `recipient${row?.id}-${row?.original?.rowIndex}`;
          const recordStatus = row?.original.outboundRecords?.status;
          return isReceipientPPOSExist === false &&
            recordStatus !== status.AVAILABLE &&
            recordStatus !== status.PENDING &&
            recordStatus !== status.AVAILABLE_FROM_TRANSFER &&
            recordStatus !== status.AVAILABLE_CO &&
            recordStatus !== status.CARRIED_OVER ? (
            <>
              <div className="flex">
                <span>{row?.original.outboundRecords?.recipient}</span>
                <Alert24
                  color="black"
                  id={tooltipId}
                  className="ml-[5px] cursor-pointer"
                />
              </div>
              <UncontrolledPopover placement="bottom" target={tooltipId}>
                <PopoverHeader>Recipient without Nabisy account</PopoverHeader>
                <PopoverBody>
                  This recipient doesn’t have a Nabisy account, so we can only
                  generate the ISCC EU PoS. If a Nabisy PoS or PPoS needs to be
                  generated, please send us feedback using the send feedback
                  button.
                </PopoverBody>
              </UncontrolledPopover>
            </>
          ) : (
            row?.original.outboundRecords?.recipient
          );
        },
      },
      {
        header: "Document",
        accessorKey: "document",
        key: "document",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.document || "";
        },
        cell: ({cell, row}) => {
          const tooltipId = `outgoingDocument${row?.id}-${row?.original?.rowIndex}`;
          const isInprogress =
            row?.original.outboundRecords?.document === "IN PROGRESS";

          return isInprogress ? (
            "Document processing…"
          ) : (
            <>
              <Link
                to={appendParamsToUrl(
                  "/mass-balance/outgoing-view",
                  [
                    selectedCountry,
                    division,
                    row?.original.outboundRecords?.certificateOutboundEuId,
                  ],
                  {
                    redirectTo: appendParamsToUrl("/mass-balance", [
                      selectedCountry,
                      division,
                      currentFilterValues?.mbBalanceTypeCode,
                      currentFilterValues?.mbLocationGroupName,
                      currentFilterValues?.mbPeriodName,
                      currentFilterValues?.mbBalanceGroupName,
                    ]),
                  },
                )}
                className="link-dark"
                id={tooltipId}
              >
                <u className="flex">
                  {row?.original.outboundRecords?.document && <FilePdf24 />}
                  <ColumnHighlight
                    value={row?.original.outboundRecords?.document}
                    columnFilter={getFilterValue(cell, "document")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {row?.original.outboundRecords?.document ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Quantity(out) MT",
        accessorKey: "quantityOutMt",
        key: "quantityOutMt",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.quantityOutMt,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecords?.quantityOutMt;
          return getFormattedNumberWithCheck(value, NUMBER_OF_DECIMALS);
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDateOutgoing",
        key: "issuanceDateOutgoing",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFnOutgoing",
        accessorFn: (row) => {
          return row?.outboundRecords?.issuanceDateOutgoing;
        },
        cell: ({row}) => {
          return (
            row?.original.outboundRecords?.issuanceDateOutgoing &&
            moment(row?.original.outboundRecords?.issuanceDateOutgoing).format(
              dateFormat,
            )
          );
        },
      },
      {
        header: "Product",
        accessorKey: "product",
        key: "product",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.product;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.product;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.transportSystemOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.transportSystemOutgoing;
        },
      },
      {
        header: "Ship to location",
        accessorKey: "shipToLocation",
        key: "shipToLocation",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.outboundRecords?.shipToLocation;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.shipToLocation;
        },
      },
      {
        header: "Dispatch location",
        accessorKey: "dispatchLocation",
        key: "dispatchLocation",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.outboundRecords?.dispatchLocation;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.dispatchLocation;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.vesselNameOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.vesselNameOutgoing;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotal",
        key: "ghgTotal",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.ghgTotal,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecords?.ghgTotal;
          return getFormattedNumberWithCheck(value, NUMBER_OF_DECIMALS);
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavingsOutgoing",
        key: "ghgSavingsOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return getFormattedNumberWithCheck(
            row?.outboundRecords?.ghgSavingsOutgoing,
            NUMBER_OF_DECIMALS,
          );
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecords?.ghgSavingsOutgoing;
          return getFormattedNumberWithCheck(value, NUMBER_OF_DECIMALS);
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.status;
        },
        cell: ({row}) => {
          const currentStatus = getCurrentStatus(row);
          const bgColor = getStatusColor(currentStatus);

          return (
            <span
              className={`whitespace-nowrap uppercase status-badge-mass-balance disabled:text-white ${bgColor}`}
            >
              {getStatusValue(currentStatus)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return (
            <ActionDropdown
              row={row}
              table={table}
              navigateToAllocation={navigateToAllocation}
              currentFilterValues={currentFilterValues}
              periodStatus={periodStatus}
            />
          );
        },
      },
    ],
  },
];

export default getColumnsSafTrTrs;
