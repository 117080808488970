import PropTypes from "prop-types";
import {createContext, useContext, useMemo, useReducer} from "react";

const StatusContext = createContext();
StatusContext.displayName = "StatusContext";

const statusReducer = (state, action) => {
  switch (action.type) {
    case "setDraft": {
      return {...state, isDraft: action.payload};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const StatusProvider = ({children, ...otherProps}) => {
  const initialState = {
    isDraft: true,
  };
  const [state, dispatch] = useReducer(statusReducer, initialState);

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  return (
    <StatusContext.Provider value={value} {...otherProps}>
      {children}
    </StatusContext.Provider>
  );
};

StatusProvider.propTypes = {
  children: PropTypes.node,
};

export const useStatus = () => {
  const context = useContext(StatusContext);
  if (context === undefined) {
    throw new Error("useStatus must be used inside StatusProvider");
  }

  return context;
};
