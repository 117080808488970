import PropTypes from "prop-types";
import DHDSDataGridItem from "./DHDSDataGridItem";

const DHDSDataGrid = ({data, title, dataColumn, refetch}) => {
  return (
    <div data-test="data-grid">
      <div className="pb-2 border-b-4">{title}</div>
      <div data-test="data-grid-column">
        {dataColumn.map((item, index) => {
          if (item.key?.includes("rundown_density")) {
            return (
              <DHDSDataGridItem
                key={index}
                batchId={data?.batch_id}
                dataBbl={data?.[`${item.key}`] ?? ""}
                label={item.label}
                aimTag={item.aimTag}
                metric={item.metric}
                refetch={refetch}
              />
            );
          }
          return (
            <DHDSDataGridItem
              key={index}
              batchId={data?.batch_id}
              dataBbl={data?.[`${item.key}_bbl`] ?? ""}
              dataLb={data?.[`${item.key}_lb`] ?? ""}
              label={item.label}
              aimTag={item.aimTag}
              refetch={refetch}
            />
          );
        })}
      </div>
    </div>
  );
};

DHDSDataGrid.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  dataColumn: PropTypes.array,
  refetch: PropTypes.func,
};

export default DHDSDataGrid;
