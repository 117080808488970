import {DIV_CODE_COPRO, DIV_CODE_GF, DIV_CODE_SAF} from "./divisionDetails";

export const documentStatus = {
  COMPLETED: "completed",
  READY_TO_ASSIGN: "ready_to_assign",
};

export const getFeatureNameByDivCode = (divisionCode) => {
  switch (divisionCode?.toUpperCase()) {
    case DIV_CODE_COPRO:
      return "coProcessing";
    case DIV_CODE_SAF:
      return "saf";
    case DIV_CODE_GF:
      return "groundFuels";
    default:
      return "BioVerse";
  }
};

export const getDivCodeByFeatureName = (featureName) => {
  switch (featureName) {
    case "coProcessing":
      return DIV_CODE_COPRO;
    case "saf":
      return DIV_CODE_SAF;
    case "groundFuels":
      return DIV_CODE_GF;
    default:
      return "BioVerse";
  }
};

export default {};
