import {Down24, Kebab24, Up24} from "@bphxd/ds-core-react/lib/icons";
import {documentStatus} from "constants/docManager";
import {
  RejectionMessages,
  docStatusClassNames,
  docStatusMessages,
} from "content/DocStatus";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedDecimal} from "utils/numberUtil";
import HighLightedText from "../../HighLightedText";
import StatusChip from "../../StatusChip";
import EditModal from "./EditModal";
import VersionModal from "./VersionModal";

const RenderLink = (
  id,
  text,
  pageNo,
  cell,
  selectedCountry,
  siteReferenceId,
  divisionId,
) => {
  const {country} = useParams();
  return (
    <Link
      to={{
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${id}`,
        search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
      }}
      className="link-dark"
    >
      <u>
        <HighLightedText
          value={text}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </u>
    </Link>
  );
};

const RenderDropdown = ({
  certificateInboundEuId,
  contractNumber,
  mbBalanceGroupName,
  mbLocationGroupName,
  mbBalanceTypeCode,
  docNumberSplit,
  selectedCountry,
  pageNo,
  state,
  handleMarkAsComplete,
  sdNumber,
  siteReferenceId,
  divisionId,
  divisionCode,
  hideLtpButton,
  previousVersionsCount,
  previousVersions,
  ENABLE_DOC_MAN_EDIT,
  editFlag,
  row,
}) => {
  const navigate = useNavigate();
  const {country} = useParams();
  const [versionModal, setVersionModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setVersionModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <>
      <VersionModal
        versionModal={versionModal}
        setVersionModal={setVersionModal}
        previousVersions={previousVersions}
        selectedCountry={selectedCountry}
        pageNo={pageNo}
        siteReferenceId={siteReferenceId}
        divisionId={divisionId}
        previousVersionsCount={previousVersionsCount}
        certificateInboundEuId={certificateInboundEuId}
      />
      <EditModal
        editModal={editModal}
        setEditModal={setEditModal}
        certificateInboundEuId={certificateInboundEuId}
        selectedCountry={selectedCountry}
        pageNo={pageNo}
        siteReferenceId={siteReferenceId}
        divisionId={divisionId}
      />
      <UncontrolledDropdown
        direction="down"
        className="doc-manager-action-dropdown"
      >
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0 doc-manager-action-dropdown-button"
        >
          <Kebab24></Kebab24>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu" container="body">
          <>
            <DropdownItem
              onClick={() =>
                navigate({
                  pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                    certificateInboundEuId,
                  )}`,
                  search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                })
              }
            >
              View document
            </DropdownItem>
            {ENABLE_DOC_MAN_EDIT &&
              row?.depth === 0 &&
              (state === "ready_to_assign" || state === "split") && (
                <DropdownItem
                  onClick={() => setEditModal(true)}
                  disabled={
                    (state !== "ready_to_assign" && state !== "split") ||
                    (state === "split" && !editFlag)
                  }
                >
                  Edit document
                </DropdownItem>
              )}

            <DropdownItem
              disabled={
                state !== "ready_to_assign" &&
                state !== "split" &&
                state !== "pending"
              }
              onClick={() => {
                navigate({
                  pathname: `/link-to-purchase/${selectedCountry}/${divisionCode?.toLowerCase()}`,
                  search: `?contractNumber=${encodeURIComponent(
                    contractNumber,
                  )}&balanceGroup=${encodeURIComponent(
                    mbBalanceGroupName,
                  )}&locationGroup=${encodeURIComponent(
                    mbLocationGroupName,
                  )}&docNumberSplit=${encodeURIComponent(
                    docNumberSplit,
                  )}&sdNumber=${encodeURIComponent(
                    sdNumber,
                  )}&mbBalanceTypeCode=${encodeURIComponent(
                    mbBalanceTypeCode,
                  )}`,
                });
              }}
            >
              Link to purchase
            </DropdownItem>

            <DropdownItem
              onClick={() => setVersionModal(true)}
              disabled={
                previousVersionsCount === null || previousVersionsCount === 0
              }
            >
              Version history ({previousVersionsCount ?? 0})
            </DropdownItem>
          </>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RenderDropdown.propTypes = {
  certificateInboundEuId: PropTypes.string,
  contractNumber: PropTypes.string,
  mbBalanceGroupName: PropTypes.string,
  mbLocationGroupName: PropTypes.string,
  docNumberSplit: PropTypes.string,
  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  state: PropTypes.string,
  handleMarkAsComplete: PropTypes.func,
  sdNumber: PropTypes.string,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
  hideLtpButton: PropTypes.string,
  divisionCode: PropTypes.string,
  previousVersionsCount: PropTypes.number,
  previousVersions: PropTypes.array,
  mbBalanceTypeCode: PropTypes.string,
  ENABLE_DOC_MAN_EDIT: PropTypes.bool,
  editFlag: PropTypes.bool,
  row: PropTypes.object,
};

const renderStatus = (text, cell) => {
  const defaultClassName = docStatusClassNames["Failed to process"];
  const className = docStatusClassNames[text];

  return (
    <StatusChip
      className={className || defaultClassName}
      message={text}
      globalFilter={cell.getContext().table.getState().globalFilter}
    />
  );
};

export const getColumnsSpainGMB = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  handleMarkAsComplete,
  siteReferenceId,
  divisionId,
  divisionCode,
  hideLtpButton,
  ENABLE_DOC_MAN_EDIT,
) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <button
            onClick={row.getToggleExpandedHandler()}
            className="!px-0 doc-table-expand-button"
            type="button"
          >
            {row.getIsExpanded() ? <Up24 /> : <Down24 />}
          </button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Document",
      accessorKey: "sdNumber",
      key: "sdNumber",
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          row?.original?.docNumberSplit === null
            ? row?.original?.sdNumber
            : row?.original?.docNumberSplit,
          table.getState()?.pagination?.pageIndex,
          cell,
          selectedCountry,
          siteReferenceId,
          divisionId,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Raw material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity MT",
      accessorKey: "feedstockQty",
      key: "feedstockQty",
      size: 100,
      visible: true,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      cell: ({cell, row}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass Balance",
      accessorKey: "mbLocation",
      key: "mbLocation",
      size: 110,
      visible: true,
      defaultOption: true,
      accessorFn: ({mbPeriodName, mbLocationGroupName, state}) => {
        return (
          (state === documentStatus.COMPLETED ||
            state === documentStatus.READY_TO_ASSIGN) &&
          `${mbPeriodName} - ${mbLocationGroupName}`
        );
      },
      cell: ({getValue, row, cell}) => {
        if (
          row.original.state === documentStatus.COMPLETED &&
          row.original.mbLocationGroupName &&
          row.original.mbPeriodName &&
          row.original.mbBalanceTypeCode
        )
          return (
            <Link
              to={appendParamsToUrl("/mass-balance", [
                selectedCountry,
                divisionCode,
                row.original.mbBalanceTypeCode,
                row.original.mbLocationGroupName,
                row.original.mbPeriodName,
                row.original.mbBalanceGroupName,
              ])}
              className="link-dark"
              state={row.original.mbPeriodId}
            >
              <u>{cell.getValue()}</u>
            </Link>
          );

        return "";
      },
    },
    {
      header: "Issuance date",
      accessorKey: "feedstockIssueDatez",
      key: "feedstockIssueDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Upload date",
      accessorKey: "bvAuditCreatedDatez",
      key: "bvAuditCreatedDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Product",
      accessorKey: "productType",
      key: "productType",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Additional information",
      accessorKey: "additionalInfo",
      key: "additionalInfo",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Country of origin",
      accessorKey: "rawMaterialOriginCountryName",
      key: "rawMaterialOriginCountryName",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m³",
      accessorKey: "feedstockQtyM3",
      key: "feedstockQtyM3",
      size: 200,
      visible: false,
      dataType: "number",
      accessorFn: ({feedstockQtyUom, feedstockQty}) => {
        return feedstockQtyUom === "M3" ? feedstockQty : "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG savings %",
      accessorKey: "ghgEmissionPercentageIn",
      key: "ghgEmissionPercentageIn",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 2)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total kgCO2/dry-t",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Scheme",
      accessorKey: "certificationSystem",
      key: "certificationSystem",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch address",
      accessorKey: "supplierDispatchAddress",
      key: "supplierDispatchAddress",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch date",
      accessorKey: "materialDispatchDatez",
      key: "materialDispatchDatez",
      size: 200,
      visible: false,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    // This will be used later
    // {
    //   header: "Document type",
    //   accessorKey: "documentType",
    //   key: "documentType",
    //   size: 200,
    //   visible: false,
    //   cell: ({cell}) => {
    //     return (
    //       <HighLightedText
    //         value={cell.getValue()}
    //         globalFilter={cell.getContext().table.getState().globalFilter}
    //       />
    //     );
    //   },
    // },
    {
      header: "Reason for rejection",
      accessorKey: "detailedState",
      key: "detailedState",
      size: 200,
      visible: false,
      accessorFn: ({detailedState}) => {
        const defaultMessage = "";
        const message = RejectionMessages[detailedState];
        return message || defaultMessage;
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Status",
      key: "state",
      disabled: true,
      accessorKey: "state",
      visible: true,
      draggaingDisabled: true,
      accessorFn: ({state}) => {
        const defaultMessage = docStatusMessages.failed_to_process;
        const message = docStatusMessages[state];
        return message || defaultMessage;
      },
      cell: ({getValue, cell, row}) => {
        return renderStatus(getValue(), cell);
      },
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            certificateInboundEuId,
            state,
            sdNumber,
            contractNumber,
            mbLocationGroupName,
            mbBalanceGroupName,
            mbBalanceTypeCode,
            docNumberSplit,
            versionCount,
            versionHistoryData,
            editFlag,
          },
        },
        table,
        row,
      }) => {
        return (
          <RenderDropdown
            certificateInboundEuId={certificateInboundEuId}
            contractNumber={contractNumber}
            mbBalanceGroupName={mbBalanceGroupName}
            mbLocationGroupName={mbLocationGroupName}
            mbBalanceTypeCode={mbBalanceTypeCode}
            docNumberSplit={docNumberSplit}
            selectedCountry={selectedCountry}
            pageNo={table.getState()?.pagination?.pageIndex}
            state={state}
            handleMarkAsComplete={handleMarkAsComplete}
            sdNumber={sdNumber}
            siteReferenceId={siteReferenceId}
            divisionId={divisionId}
            hideLtpButton={hideLtpButton}
            divisionCode={divisionCode}
            previousVersionsCount={versionCount}
            previousVersions={versionHistoryData}
            ENABLE_DOC_MAN_EDIT={ENABLE_DOC_MAN_EDIT}
            editFlag={editFlag}
            row={row}
          />
        );
      },
    },
  ];
};

export default getColumnsSpainGMB;
