import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import BatchAllocationTable from "./BatchAllocationTable";

const BatchAllocationCard = ({
  title,
  testId,
  batchResourceId,
  ...otherProps
}) => {
  return (
    <div className="mb-8" data-test={testId}>
      <Card className="card-border rounded-6">
        <CardBody>
          <div className="text-[26px]">{title}</div>
          <div>
            <BatchAllocationTable
              batchResourceId={batchResourceId}
              {...otherProps}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

BatchAllocationCard.propTypes = {
  title: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  batchResourceId: PropTypes.string.isRequired,
};

export default BatchAllocationCard;
