import {BATCH_FEEDSTOCKS_ANIMAL_FATS} from "../constants/batches";

export const buildBatchAllocationTableDataSet = (feedstocks) => {
  const animalFats = feedstocks.filter((feedstock) =>
    BATCH_FEEDSTOCKS_ANIMAL_FATS.includes(feedstock.feedstock_type),
  );
  const nonAnimalFats = feedstocks.filter(
    (feedstock) =>
      !BATCH_FEEDSTOCKS_ANIMAL_FATS.includes(feedstock.feedstock_type),
  );

  const totalPercentage = feedstocks.reduce(
    (acc, cur) => acc + cur.feedstock_amount_lb,
    0,
  );
  const totalAmountLb = feedstocks.reduce(
    (acc, cur) => acc + cur.feedstock_amount_lb,
    0,
  );
  const totalAmountBBL = feedstocks.reduce(
    (acc, cur) => acc + cur.feedstock_amount_bbl,
    0,
  );

  return [
    ...(animalFats.length > 0
      ? [
          {
            feedstock_type: "Animal fats",
            feedstock_percentage: `${animalFats
              .reduce((acc, cur) => acc + cur.feedstock_percentage, 0)
              .toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`,
            feedstock_amount_lb: `${animalFats
              .reduce((acc, cur) => acc + cur.feedstock_amount_lb, 0)
              .toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} lb`,
            feedstock_amount_bbl: `${animalFats
              .reduce((acc, cur) => acc + cur.feedstock_amount_bbl, 0)
              .toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} bbl`,
            childrenFeedstocks: [
              ...animalFats.map((feedstock) => ({
                ...feedstock,
                feedstock_percentage: `${feedstock.feedstock_percentage}%`,
                feedstock_amount_lb: `${feedstock.feedstock_amount_lb.toLocaleString()} lb`,
                feedstock_amount_bbl: `${feedstock.feedstock_amount_bbl.toLocaleString()} bbl`,
              })),
            ],
          },
        ]
      : []),
    ...nonAnimalFats.map((feedstock) => ({
      ...feedstock,
      feedstock_percentage: `${feedstock.feedstock_percentage}%`,
      feedstock_amount_lb: `${feedstock.feedstock_amount_lb.toLocaleString()} lb`,
      feedstock_amount_bbl: `${feedstock.feedstock_amount_bbl.toLocaleString()} bbl`,
    })),
    {
      feedstock_type: "Total feedstock composition",
      feedstock_percentage: `${
        totalPercentage >= 99.5
          ? 100
          : totalPercentage.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
      }%`,
      feedstock_amount_lb: `${totalAmountLb.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} lb`,
      feedstock_amount_bbl: `${totalAmountBBL.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} bbl`,
    },
  ];
};

export default buildBatchAllocationTableDataSet;
