import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import {
  RejectionMessages,
  docStatusClassNames,
  docStatusMessages,
} from "content/DocStatus";
import {INCOMING_DOC_STATUS} from "modules/DocManager/constants";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedDecimal} from "utils/numberUtil";
import HighLightedText from "../../HighLightedText";
import StatusChip from "../../StatusChip";
import EditModal from "./EditModal";
import VersionModal from "./VersionModal";
import "./index.css";

const RenderLink = (
  id,
  text,
  pageNo,
  cell,
  selectedCountry,
  siteReferenceId,
  divisionId,
) => {
  return (
    <Link
      to={{
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${id}`,
        search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
      }}
      className="link-dark whitespace-nowrap"
    >
      <u>
        <HighLightedText
          value={text}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </u>
    </Link>
  );
};

const RenderDropdown = ({
  certificateInboundEuId,
  selectedCountry,
  pageNo,
  siteReferenceId,
  divisionId,
  previousVersions,
  previousVersionsCount,
  stateMgmt,
  contractNumber,
  mbLocationGroupName,
  mbBalanceTypeCode,
  mbBalanceGroupName,
  docNumberSplit,
  sdNumber,
  divisionCode,
  ENABLE_DOC_MAN_EDIT,
  editFlag,
  row,
}) => {
  const [versionModal, setVersionModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setVersionModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <VersionModal
        versionModal={versionModal}
        setVersionModal={setVersionModal}
        previousVersions={previousVersions}
        selectedCountry={selectedCountry}
        pageNo={pageNo}
        siteReferenceId={siteReferenceId}
        divisionId={divisionId}
        previousVersionsCount={previousVersionsCount}
        certificateInboundEuId={certificateInboundEuId}
      />
      <EditModal
        editModal={editModal}
        setEditModal={setEditModal}
        certificateInboundEuId={certificateInboundEuId}
        selectedCountry={selectedCountry}
        pageNo={pageNo}
        siteReferenceId={siteReferenceId}
        divisionId={divisionId}
      />

      <UncontrolledDropdown
        direction="down"
        className="doc-manager-action-dropdown"
      >
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0 doc-manager-action-dropdown-button"
        >
          <Kebab24></Kebab24>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu" container="body">
          <DropdownItem
            onClick={() =>
              navigate({
                pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                  certificateInboundEuId,
                )}`,
                search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
              })
            }
          >
            View document
          </DropdownItem>
          {ENABLE_DOC_MAN_EDIT &&
            row?.depth === 0 &&
            (stateMgmt === "ready_to_assign" || stateMgmt === "split") && (
              <DropdownItem
                onClick={() => setEditModal(true)}
                disabled={
                  (stateMgmt !== "ready_to_assign" && stateMgmt !== "split") ||
                  (stateMgmt === "split" && !editFlag)
                }
              >
                Edit document
              </DropdownItem>
            )}
          <DropdownItem
            disabled={stateMgmt !== "ready_to_assign"}
            onClick={() => {
              navigate(
                appendParamsToUrl(
                  "/link-to-purchase",
                  [selectedCountry, divisionCode?.toLowerCase()],
                  {
                    contractNumber,
                    balanceGroup: mbBalanceGroupName,
                    locationGroup: mbLocationGroupName,
                    mbBalanceTypeCode,
                    docNumberSplit,
                    sdNumber,
                  },
                ),
              );
            }}
          >
            Link to purchase
          </DropdownItem>
          <DropdownItem
            onClick={() => setVersionModal(true)}
            disabled={
              previousVersionsCount === null || previousVersionsCount === 0
            }
          >
            Version history ({previousVersionsCount ?? 0})
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RenderDropdown.propTypes = {
  certificateInboundEuId: PropTypes.string,
  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
  previousVersions: PropTypes.array,
  previousVersionsCount: PropTypes.number,
  stateMgmt: PropTypes.string,
  contractNumber: PropTypes.string,
  mbLocationGroupName: PropTypes.string,
  mbBalanceTypeCode: PropTypes.string,
  mbBalanceGroupName: PropTypes.string,
  docNumberSplit: PropTypes.string,
  sdNumber: PropTypes.string,
  divisionCode: PropTypes.string,
  ENABLE_DOC_MAN_EDIT: PropTypes.bool,
  editFlag: PropTypes.bool,
  row: PropTypes.object,
};

const renderStatus = (text, cell) => {
  const defaultClassName = docStatusClassNames["Failed to process"];
  const className = docStatusClassNames[text];

  return (
    <StatusChip
      className={className || defaultClassName}
      message={text}
      globalFilter={cell.getContext().table.getState().globalFilter}
    />
  );
};

const getColumnsSAF = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  siteReferenceId,
  divisionId,
  divisionCode,
  ENABLE_DOC_MAN_EDIT,
) => {
  return [
    {
      header: "Issuance date",
      accessorKey: "feedstockIssueDatez",
      key: "feedstockIssueDatez",
      size: 200,
      visible: true,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Document",
      accessorKey: "sdNumber",
      key: "sdNumber",
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          getValue(),
          table.getState()?.pagination?.pageIndex,
          cell,
          selectedCountry,
          siteReferenceId,
          divisionId,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      accessorFn: ({supplierName}) => {
        return supplierName || "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Raw material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: true,
      accessorFn: ({rawMaterialType}) => {
        return rawMaterialType || "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      accessorFn: ({recipientReceiptAddress}) => {
        return recipientReceiptAddress || "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m³",
      accessorKey: "productQty",
      key: "productQty",
      size: 100,
      visible: true,
      dataType: "number",
      accessorFn: ({productQty}) => {
        return productQty || "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass balance",
      accessorKey: "balance",
      key: "balance",
      size: 200,
      visible: true,
      accessorFn: ({mbPeriodName, mbLocationGroupName, state}) => {
        return state === INCOMING_DOC_STATUS.COMPLETED
          ? `${mbPeriodName} - ${mbLocationGroupName}`
          : "";
      },
      cell: ({
        row: {
          original: {
            mbBalanceTypeCode,
            mbBalanceGroupName,
            mbLocationGroupName,
            mbPeriodName,
            state,
          },
        },
        cell,
      }) => {
        return state === INCOMING_DOC_STATUS.COMPLETED ? (
          <Link
            to={appendParamsToUrl("/mass-balance", [
              selectedCountry,
              "SAF",
              mbBalanceTypeCode,
              mbLocationGroupName,
              mbPeriodName,
              mbBalanceGroupName,
            ])}
            className="link-dark !underline"
          >
            <HighLightedText
              value={cell.getValue()}
              globalFilter={cell.getContext().table.getState().globalFilter}
            />
          </Link>
        ) : (
          ""
        );
      },
    },
    {
      header: "Upload date",
      accessorKey: "bvAuditCreatedDatez",
      key: "bvAuditCreatedDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Product",
      accessorKey: "productType",
      key: "productType",
      visible: false,
      accessorFn: ({productType}) => {
        return productType || "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Additional information",
      accessorKey: "additionalInfo",
      key: "additionalInfo",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Country of origin",
      accessorKey: "rawMaterialOriginCountryName",
      key: "rawMaterialOriginCountryName",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity MT",
      accessorKey: "productQtyMT",
      key: "productQtyMT",
      size: 100,
      visible: false,
      dataType: "number",
      accessorFn: ({productQtyMT}) => {
        return productQtyMT || "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG savings %",
      accessorKey: "ghgEmissionPercentageIn",
      key: "ghgEmissionPercentageIn",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 2)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total gCO2eq/MJ",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Scheme",
      accessorKey: "certificationSystem",
      key: "certificationSystem",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch address",
      accessorKey: "supplierDispatchAddress",
      key: "supplierDispatchAddress",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch date",
      accessorKey: "materialDispatchDatez",
      key: "materialDispatchDatez",
      size: 200,
      visible: false,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Reason for rejection",
      accessorKey: "detailedState",
      key: "detailedState",
      size: 200,
      visible: false,
      accessorFn: ({detailedState}) => {
        const defaultMessage = "";
        const message = RejectionMessages[detailedState];
        return message || defaultMessage;
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Document type",
      accessorKey: "documentType",
      key: "documentType",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Status",
      key: "state",
      disabled: true,
      accessorKey: "state",
      visible: true,
      draggaingDisabled: true,
      accessorFn: ({state}) => {
        const defaultMessage = docStatusMessages.failed_to_process;
        const message = docStatusMessages[state];
        return message || defaultMessage;
      },
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            certificateInboundEuId,
            versionHistoryData,
            state,
            versionCount,
            contractNumber,
            mbLocationGroupName,
            mbBalanceTypeCode,
            mbBalanceGroupName,
            docNumberSplit,
            sdNumber,
            editFlag,
          },
        },
        table,
        row,
      }) => (
        <RenderDropdown
          certificateInboundEuId={certificateInboundEuId}
          selectedCountry={selectedCountry}
          pageNo={table.getState()?.pagination?.pageIndex}
          siteReferenceId={siteReferenceId}
          divisionId={divisionId}
          previousVersions={versionHistoryData}
          previousVersionsCount={versionCount}
          stateMgmt={state}
          contractNumber={contractNumber}
          mbBalanceGroupName={mbBalanceGroupName}
          mbLocationGroupName={mbLocationGroupName}
          mbBalanceTypeCode={mbBalanceTypeCode}
          divisionCode={divisionCode}
          docNumberSplit={docNumberSplit}
          sdNumber={sdNumber}
          ENABLE_DOC_MAN_EDIT={ENABLE_DOC_MAN_EDIT}
          editFlag={editFlag}
          row={row}
        />
      ),
    },
  ];
};

export default getColumnsSAF;
