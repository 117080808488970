import PropTypes from "prop-types";
import {useMemo} from "react";
import {PARCEL_STATUS} from "../../constants/receipts";

const StatusBadge = ({value}) => {
  const badgeColor = useMemo(() => {
    switch (value) {
      case PARCEL_STATUS.ACTUALIZED:
        return "copro-green-chip";
      case PARCEL_STATUS.SCHEDULED:
        return "copro-blue-chip";
      case PARCEL_STATUS.DRAFT:
      case PARCEL_STATUS.PENDING:
        return "copro-amber-chip";
      case PARCEL_STATUS.NOT_READY:
        return "copro-red-chip";
      default:
        return "";
    }
  }, [value]);

  return (
    <div className="flex items-center">
      <div
        data-test="copro-status-badge"
        className={`fw-medium copro-batches-chip text-uppercase ${badgeColor}`}
      >
        {value}
      </div>
    </div>
  );
};

StatusBadge.propTypes = {
  value: PropTypes.string,
};

export default StatusBadge;
