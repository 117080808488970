import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {allocationHeaders} from "modules/co-processing/constants/batches";
import {buildBatchAllocationTableDataSet} from "modules/co-processing/helpers/buildBatchAllocationTableDataSet";
import buildColumns from "modules/co-processing/helpers/buildColumns";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {Spinner} from "reactstrap";

const BatchAllocationTable = ({feedstocks = [], loading}) => {
  const dataColumns = buildColumns(allocationHeaders);

  const batchAllocationData = useMemo(
    () => buildBatchAllocationTableDataSet(feedstocks),
    [feedstocks],
  );

  const table = useReactTable({
    data: batchAllocationData,
    columns: dataColumns,
    getSubRows: (row) => row.childrenFeedstocks,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded: true,
    },
  });

  return (
    <div
      data-test="batch-allocation-details-table"
      className="w-11/12 batches-table-container"
    >
      <div className="batches-table last-bordered">
        {loading ? (
          <div className="flex mx-auto">
            <Spinner className="flex mx-auto" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="react-table table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    {/* Normal row UI */}
                    <tr className={row.depth > 0 ? "nested" : ""}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          <div>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

BatchAllocationTable.propTypes = {
  feedstocks: PropTypes.array,
  loading: PropTypes.bool,
};

export default BatchAllocationTable;
